import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import ContentBlock from "../../components/ContentBlock"
import ExternalLink from "../../components/ExternalLink"
import Interstitial from "../../components/ExternalInterstitial/Interstitial"
import HelmetWrapper from "../../components/HelmetWrapper"
import headerHero from "../../assets/images/header_advancing_the_science.jpg"
import wakixLogo from "../../assets/images/wakix-logo.svg"

import { isIE } from "react-device-detect"

const pageTitle = "Our Products | Harmony Biosciences"
const pageDescription =
  "Learn about Harmony Biosciences’ products and get full prescribing information here."
const pageKeywords = "wakix"

class ourProducts extends Component {
  constructor() {
    super()
  }

  // peaceLinkClick = () => {
  //   const isGA = typeof window.ga !== "undefined"

  //   if (isGA) {
  //     window.ga('send', 'event', 'External Link', 'click', 'thepeacenarcolepsyprogram.com')
  //   }
  //   window.open('https://thepeacenarcolepsyprogram.com/', '_blank')

  // }

  render() {
    return (
      <Layout jobCode="US-HAR-2200050/Jun 2022">
        <div id="ourProducts">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/our-products"
          />
          <Hero bg={headerHero}>
            <h1>Our Products</h1>
          </Hero>
          <ContentBlock color="green">
            <Row>
              <Col xs={12} md={4}>
                <p>
                  <img src={wakixLogo} alt="WAKIX logo" className="wakix-logo" />
                  <br />
                </p>
              </Col>
              <Col xs={12} md={4}>
                <p>
                  <ExternalLink href="https://www.wakix.com">
                    WAKIX.com
                  </ExternalLink>
                  <br />
                  <ExternalLink href="https://wakix.com/prescribing-information">
                    WAKIX Prescribing Information
                  </ExternalLink>
                </p>
              </Col>
            </Row>
          </ContentBlock>
          <ContentBlock color="white" className="no-v-space">
            <Row>
              <Col xs={12} md={12}>
                <p>WAKIX is a registered trademark of Bioprojet Europe, Ltd.</p>
              </Col>
            </Row>
          </ContentBlock>
          <Interstitial />
        </div>
      </Layout>
    )
  }
}

export default ourProducts
